<template>
    <div class="relative">
        <!-- Trigger Input -->
        <BaseInput
            v-if="filterable"
            :id="triggerId"
            :model-value="query"
            aria-controls="listbox"
            aria-haspopup="listbox"
            :placeholder="placeholder"
            :disabled="disabled"
            :loading="loading"
            :ai-autofilled="aiAutofilled"
            :error-message="errorMessage"
            :clearable="isClearableButtonVisible"
            :dark="dark"
            @clear="onClear"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @click.prevent="
                isFocused &&
                    !isInputDirty &&
                    emit('open-list', { ignoreQuery: true })
            "
            @input="onInput"
            @keydown.escape.prevent="emit('close-list')"
            @keydown.tab="removeTriggerFocus"
            @keydown.down.capture="
                !isOpened && emit('open-list', { ignoreQuery: true })
            "
        >
            <template v-if="slots['prepend-icon']" #icon>
                <div
                    class="absolute inset-y-0 left-0 flex items-center justify-center"
                >
                    <slot name="prepend-icon" />
                </div>
            </template>

            <template
                v-if="isAppendIconVisible ? !isClearableButtonVisible : false"
                #append
            >
                <span
                    :class="[
                        'absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2',
                        errorMessage && 'right-6',
                    ]"
                    @click.prevent.stop="!disabled && onTriggerIconClick()"
                >
                    <ChevronUpDownIcon
                        :class="[
                            'h-5 w-6',
                            dark ? 'text-gray-300' : 'text-gray-400',
                        ]"
                        aria-hidden="true"
                    />
                </span>
            </template>
        </BaseInput>

        <!-- Trigger Button -->
        <button
            v-else
            :id="triggerId"
            :value="selectedOptionDisplayText"
            :disabled="disabled"
            :aria-expanded="isOpened"
            role="combobox"
            aria-controls="listbox"
            aria-haspopup="listbox"
            :class="[
                'relative z-0 w-full rounded-md border-none transition-shadow focus:outline-none',
                stateClassList,
            ]"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @click.prevent.stop="onButtonClick"
            @keydown.down.capture="!isOpened && emit('open-list')"
            @keydown.tab="isOpened && emit('close-list')"
        >
            <slot name="trigger-button" :is-focused="isFocused">
                <div
                    :class="[
                        'flex h-10 w-full items-center rounded-md py-2 pl-3 pr-10 text-left shadow sm:h-9 sm:text-sm',
                        slots['prepend-icon'] && '!pl-10',
                        dark
                            ? 'bg-gray-700 text-white'
                            : 'bg-white text-gray-900',
                        disabled &&
                            'pointer-events-none cursor-not-allowed bg-slate-100',
                    ]"
                >
                    <!-- Slot - Prepend icon  -->
                    <div
                        v-if="slots['prepend-icon']"
                        class="absolute inset-y-0 left-0 flex items-center justify-center"
                    >
                        <slot name="prepend-icon" />
                    </div>

                    <!-- Selected value -->
                    <slot v-if="modelValue" name="trigger-button-selected">
                        <span
                            :class="[
                                'truncate',
                                dark ? 'text-white' : 'text-gray-900',
                            ]"
                        >
                            {{ selectedOptionDisplayText }}
                        </span>
                    </slot>

                    <!-- Placeholder -->
                    <span
                        v-else
                        class="truncate whitespace-nowrap text-gray-400"
                    >
                        {{ placeholder }}
                    </span>

                    <!-- Error icon -->
                    <div
                        v-if="errorMessage"
                        class="pointer-events-none absolute inset-y-0 right-2 flex items-center"
                    >
                        <ExclamationCircleIcon
                            class="h-5 w-5 text-red-500"
                            aria-hidden="true"
                        />
                    </div>

                    <div
                        :class="[
                            'absolute inset-y-0 right-0 flex items-center justify-center pr-2',
                            errorMessage && 'right-6',
                        ]"
                    >
                        <BaseProgressCircular
                            v-if="loading"
                            size="22"
                            class="text-primary-500"
                            aria-hidden="true"
                        />

                        <button
                            v-else-if="isClearableButtonVisible"
                            tabindex="-1"
                            type="button"
                            class="h-full text-gray-400 transition-colors hover:text-gray-600"
                            @click.stop="emit('clear')"
                        >
                            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                        </button>

                        <ChevronUpDownIcon
                            v-else-if="!hideAppendIcon"
                            :class="[
                                'h-5 w-6',
                                dark ? 'text-gray-300' : 'text-gray-400',
                            ]"
                            aria-hidden="true"
                            @click.stop="
                                isOpened
                                    ? emit('close-list')
                                    : emit('open-list', { ignoreQuery: true })
                            "
                        />
                    </div>
                </div>
            </slot>

            <!-- Filled with AI -->
            <div v-if="aiAutofilled" class="absolute -right-2 -top-2">
                <BaseTooltip
                    :content="t('filled-with-ai')"
                    class="whitespace-nowrap"
                >
                    <SparklesIcon
                        :class="[
                            'h-5 bg-white p-0.5 transition-colors',
                            isFocused ? 'text-primary-500' : 'text-blue-500/50',
                        ]"
                        aria-hidden="true"
                    />
                </BaseTooltip>
            </div>
        </button>
    </div>
</template>

<script setup lang="ts" generic="T = any">
import { computed, useSlots, ref } from 'vue'
import {
    ChevronUpDownIcon,
    ExclamationCircleIcon,
    SparklesIcon,
} from '@heroicons/vue/24/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { get } from 'lodash-es'

const State = {
    disabled: {
        static: 'ring-1 ring-gray-300',
        focused: 'ring-1 ring-gray-300',
    },
    error: {
        static: 'text-red-900 ring-red-300',
        focused: 'ring-2 ring-red-500',
    },
    autofilled: {
        static: 'ring-2 ring-blue-500/50',
        focused: 'ring-2 ring-primary-500',
    },
    dark: {
        static: 'ring-1 ring-gray-600',
        focused: 'ring-2 ring-primary-600',
    },
    default: {
        static: 'ring-1 ring-gray-300',
        focused: 'ring-2 ring-primary-500',
    },
}

const props = defineProps({
    modelValue: {
        type: [Object, String, Number] as PropType<any>,
        default: undefined,
    },
    query: {
        type: String,
        default: '',
    },
    id: {
        type: String,
        required: true,
    },
    optionText: {
        type: [String, Function] as PropType<string | ((item: T) => string)>,
        default: 'value',
    },
    optionValue: {
        type: String,
        default: 'value',
    },
    placeholder: {
        type: String,
        default: '',
    },
    dark: {
        type: Boolean,
        default: false,
    },
    areItemsObjects: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    errorMessage: {
        type: String,
        default: '',
    },
    filterable: {
        type: Boolean,
        default: false,
    },
    clearable: {
        type: Boolean,
        default: false,
    },
    isOpened: {
        type: Boolean,
        default: false,
    },
    hideAppendIcon: {
        type: Boolean,
        default: false,
    },
    isInputDirty: {
        type: Boolean,
        default: false,
    },
    aiAutofilled: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits<{
    (event: 'input', value: string): void
    (event: 'open-list', opts?: { ignoreQuery?: boolean }): void
    (event: 'close-list'): void
    (event: 'clear'): void
}>()

const triggerId = `${props.id}-trigger`

const slots = useSlots()
const { t } = useI18n()

const isFocused = ref(false)

const selectedOptionDisplayText = computed(() => {
    if (props.areItemsObjects) {
        if (typeof props.optionText === 'function') {
            return props.optionText(props.modelValue)
        }

        return get(props.modelValue, props.optionText)
    }

    return props.modelValue
})

const isClearableButtonVisible = computed(() => {
    return props.clearable && props.modelValue && !props.loading
})

const isAppendIconVisible = computed(() => {
    if (props.hideAppendIcon || props.loading) return false

    return true
})

const stateClassList = computed(() => {
    if (inputState.value === 'default' && slots['trigger-button']) {
        return ''
    }

    const stateClassess = State[inputState.value]

    return isFocused.value ? stateClassess.focused : stateClassess.static
})

const inputState = computed<keyof typeof State>(() => {
    if (props.disabled) return 'disabled'
    if (props.errorMessage) return 'error'
    if (props.aiAutofilled) return 'autofilled'
    if (props.dark) return 'dark'

    return 'default'
})

function onInput(event: Event) {
    const value = (event.target as HTMLInputElement).value

    emit('input', value)
}

function onClear() {
    emit('clear')
}

function onButtonClick() {
    const btn = document.querySelector(`#${triggerId}`) as HTMLButtonElement

    if (props.isOpened) {
        // TODO: fix for Safari's lack of focus on button @click
        emit('close-list')
        isFocused.value = false

        if (!props.filterable) btn.blur()
    } else {
        // TODO: fix for Safari's lack of focus on button @click
        emit('open-list')
        isFocused.value = true

        if (!props.filterable) btn.focus()
    }
}

function onTriggerIconClick() {
    props.isOpened
        ? emit('close-list')
        : emit('open-list', { ignoreQuery: true })

    if (props.filterable) {
        const input = document.querySelector(
            `#${triggerId} input`,
        ) as HTMLInputElement

        input.focus()
    }
}

function removeTriggerFocus(event: KeyboardEvent) {
    ;(event?.target as HTMLInputElement)?.blur?.()

    emit('close-list')
}

defineExpose({ triggerId })
</script>
