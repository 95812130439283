<template>
    <div :id="ELEMENT_ID" :class="['flex flex-col', isMaskOpened && 'z-[1]']">
        <!-- Mask -->
        <Transition
            is="div"
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="isMaskOpened"
                class="fixed left-0 top-0 h-full w-full bg-black bg-opacity-60 backdrop-blur-md"
                @click="onCloseList"
            />
        </Transition>

        <!-- Label -->
        <BaseLabel
            v-if="label"
            :for="`${ELEMENT_ID}-trigger`"
            :required="required"
        >
            {{ label }}
        </BaseLabel>

        <!-- Trigger -->
        <BaseSelectTrigger
            ref="triggerRef"
            v-bind="sharedProps"
            :filterable="filterable"
            :clearable="clearable"
            :placeholder="placeholder"
            :is-opened="isOpened"
            :ai-autofilled="aiAutofilled"
            :error-message="errorMessage"
            :loading="isSelectLoading"
            :disabled="disabled"
            :hide-append-icon="hideAppendIcon"
            :class="isMaskOpened && 'z-20'"
            @input="onInputChange"
            @open-list="onOpenList"
            @close-list="onCloseList"
            @clear="onClear"
        >
            <template v-if="slots['prepend-icon']" #prepend-icon>
                <slot name="prepend-icon" />
            </template>

            <template
                v-if="slots['trigger-button']"
                #trigger-button="{ isFocused }"
            >
                <slot
                    name="trigger-button"
                    :selected="internalValue"
                    :is-focused="isFocused"
                />
            </template>

            <template
                v-if="slots['trigger-button-selected']"
                #trigger-button-selected
            >
                <slot
                    name="trigger-button-selected"
                    :selected="internalValue"
                />
            </template>
        </BaseSelectTrigger>

        <!-- List -->
        <div
            ref="popperRef"
            class="absolute z-20 w-full"
            :style="{ width: `${popperWidth}px` }"
        >
            <Transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 -translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 -translate-y-1"
            >
                <BaseSelectList
                    v-if="isListEligibleToOpen"
                    v-bind="sharedProps"
                    :options="filteredList"
                    :hide-selected-check="hideSelectedCheck"
                    :overlap="overlap"
                    :highlight-results="highlightResults && filterable"
                    :class="listItemsClass"
                    :is-input-dirty="isInputDirty"
                    @update:model-value="onOptionSelect"
                    @close-list="onCloseList"
                    @vue:before-mount="updatePopper"
                >
                    <template v-if="slots['list-prepend']" #list-prepend>
                        <slot name="list-prepend" />
                    </template>

                    <template
                        v-if="slots['list-item']"
                        #list-item="listItemProps"
                    >
                        <slot name="list-item" v-bind="listItemProps" />
                    </template>

                    <template v-if="slots['empty']" #empty>
                        <slot name="empty" />
                    </template>

                    <template v-if="slots['list-append']" #list-append>
                        <slot name="list-append" />
                    </template>
                </BaseSelectList>
            </Transition>
        </div>

        <!-- Error message-->
        <p
            v-if="errorMessage && !filterable"
            class="mt-1 flex items-center text-xs text-red-500"
        >
            {{ errorMessage }}
        </p>

        <!-- Hint -->
        <p v-if="hint" class="mt-2 text-sm leading-5 text-gray-500">
            {{ hint }}
        </p>
    </div>
</template>

<script setup lang="ts" generic="T = any">
import {
    computed,
    ref,
    unref,
    nextTick,
    watch,
    useSlots,
    onBeforeUnmount,
} from 'vue'
import { nanoid } from 'nanoid/non-secure'
import { useElementSize, type MaybeComputedElementRef } from '@vueuse/core'
import { get, isPlainObject } from 'lodash-es'
import popper from '~/utils/popper'
import type { Placement } from '@popperjs/core/lib/enums'

export interface SelectOpenConditions {
    isEmpty: boolean
    isInputDirty: boolean
    query: string
}

const ELEMENT_ID = `select-${nanoid(4)}`

const props = defineProps({
    /** Array of options to render to select's list */
    options: {
        type: Array as PropType<T[]>,
        default: () => [],
    },
    /** What property from the option object should be shown as text for each element */
    optionText: {
        type: [String, Function] as PropType<string | ((item: T) => string)>,
        default: 'value',
    },
    /** What property from the object should be used as a value for each element */
    optionValue: {
        type: String,
        default: 'value',
    },
    /** Select label */
    label: {
        type: String,
        default: '',
    },
    /** Select placeholder when nothing's selected yet */
    placeholder: {
        type: String,
        default: '',
    },
    /** Help text that appears below the select */
    hint: {
        type: String,
        default: '',
    },
    /** Whether the select items are filterable. If yes, the Select will turn into an Input element for search purposes */
    filterable: {
        type: Boolean,
        default: false,
    },
    /** Should the v-model return the whole object entity */
    returnWhole: {
        type: Boolean,
        default: false,
    },
    /** Whether matching options should highlight the searched text
     ** 💡 Requires "filterable" prop set to true
     ** 💡 Does not work if "list-item" slot is used
     */
    highlightResults: {
        type: Boolean,
        default: false,
    },
    /** Whether select should clear when one of the options gets selected */
    clearOnSelect: {
        type: Boolean,
        default: false,
    },
    /** Whether select is disabled */
    disabled: {
        type: Boolean,
        default: false,
    },
    errorMessage: {
        type: String,
        default: '',
    },
    dark: {
        type: Boolean,
        default: false,
    },
    maskOnOpen: {
        type: Boolean,
        default: false,
    },
    /** Desired placement for options list. If placement becomes impossible, the best one will be picked */
    placement: {
        type: String as PropType<Placement>,
        default: 'bottom' as Placement,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    /** Class string applied to the list of items container */
    listItemsClass: {
        type: String,
        default: '',
    },
    clearable: {
        type: Boolean,
        default: false,
    },
    hideSelectedCheck: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    overlap: {
        type: Boolean,
        default: true,
    },
    hideAppendIcon: {
        type: Boolean,
        default: false,
    },
    openConditions: {
        type: Function as PropType<
            (conditions: SelectOpenConditions) => boolean
        >,
        default: undefined,
    },
})

const emit = defineEmits<{
    (event: 'input-change', value: string): void
    (event: 'list-closed'): void
}>()

const modelValue = defineModel({
    type: [Object, String, Number, Boolean] as PropType<any>,
    default: undefined,
})

const aiAutofilled = defineModel('aiAutofilled', {
    type: Boolean,
    default: false,
})

const slots = useSlots()
const { createPopper, destroyPopper, updatePopper } = popper()

const internalValue = ref(modelValue.value)

const areItemsObjects = computed(
    () => isPlainObject(internalValue.value) || isPlainObject(props.options[0]),
)

/** ##### Detect side-effects such as locale change and update accordingly ###### */
const activeOptionText = computed(() => getOptionText(internalValue.value))
watch(activeOptionText, (text, oldText) => {
    if (text !== oldText) query.value = text
})

const activeOption = computed(() => {
    if (areItemsObjects.value && isPlainObject(internalValue.value)) {
        return (
            props.options.find(
                (item) =>
                    get(item, props.optionValue) ===
                    get(internalValue.value, props.optionValue),
            ) || internalValue.value
        )
    }

    return (
        props.options.find((item) => item === internalValue.value) ||
        internalValue.value
    )
})
watch(activeOption, (val) => (internalValue.value = val))
/** ############################################################################## */

const isOpened = ref(false)
const ignoreFilteredListQuery = ref(false)
const isInputDirty = ref(false)
const query = ref(getOptionText(modelValue.value))
const internalLoading = ref(false)
const triggerRef = ref()
const popperRef = ref<HTMLElement | null>(null)

const { width: popperWidth } = useElementSize(
    triggerRef as MaybeComputedElementRef,
    undefined,
    {
        box: 'border-box',
    },
)

const filteredList = computed(() => {
    const queryToLower = toLowerTrimString(query.value)

    if (!props.filterable || !queryToLower || ignoreFilteredListQuery.value) {
        return props.options
    }

    if (areItemsObjects.value) {
        return props.options.filter((option) => {
            if (typeof props.optionText === 'function') {
                return toLowerTrimString(props.optionText(option)).includes(
                    queryToLower,
                )
            }

            const optionText = getOptionText(option)

            return toLowerTrimString(optionText).includes(queryToLower)
        })
    }

    return props.options.filter(
        (option) => toLowerTrimString(option as string) === queryToLower,
    )
})

const sharedProps = computed(() => ({
    modelValue: internalValue.value,
    id: ELEMENT_ID,
    optionValue: props.optionValue,
    optionText: props.optionText,
    areItemsObjects: areItemsObjects.value,
    query: query.value,
    dark: props.dark,
}))

const isListEligibleToOpen = computed(() => {
    if (props.openConditions) {
        const valid = props.openConditions({
            isEmpty: filteredList.value.length === 0,
            isInputDirty: isInputDirty.value,
            query: query.value,
        })

        if (!valid) return false
    }

    return isOpened.value && !isSelectLoading.value && !props.disabled
})

const isMaskOpened = computed(
    () => props.maskOnOpen && isListEligibleToOpen.value,
)

const isSelectLoading = computed(() => props.loading || internalLoading.value)

// Watch modelValue and change internal value
watch(
    modelValue,
    (val) => {
        const value = unref(val || modelValue.value)

        if (areItemsObjects.value && !isPlainObject(value)) {
            internalValue.value =
                props.options.find(
                    (item) => get(item, props.optionValue) === value,
                ) || value
        } else internalValue.value = value
    },
    { immediate: true, deep: true },
)

// Watch internal value so we can emit new value to parent
watch(internalValue, (valueRefNew: T, valueRefOld: T) => {
    const value = getOptionValue(valueRefNew)
    const oldValue = getOptionValue(valueRefOld)
    const shouldEmit = (value || props.clearable) && value !== oldValue

    if (shouldEmit) {
        if (props.filterable) {
            query.value = getOptionText(valueRefNew)
        }

        modelValue.value = value
    }
})

onBeforeUnmount(() => {
    destroyPopper()

    if (props.maskOnOpen) setBodyOverflowHidden(false)
})

function getOptionValue(option: T) {
    return !areItemsObjects.value || props.returnWhole
        ? option
        : get(option, props.optionValue)
}

function getOptionText(option?: T): string {
    if (!option) return ''

    if (areItemsObjects.value) {
        if (typeof props.optionText === 'function') {
            return props.optionText(option)
        }

        return String(get(option, props.optionText) || '')
    }

    return String(option)
}

function onOpenList(opts?: { ignoreQuery?: boolean }) {
    if (props.maskOnOpen) setBodyOverflowHidden(true)

    ignoreFilteredListQuery.value = opts?.ignoreQuery ?? false
    isOpened.value = true

    const trigger = getTriggerElementNode()

    createPopper(trigger, popperRef.value!, {
        placement: props.placement,
        strategy: 'fixed',
    })
}

function onCloseList() {
    isOpened.value = false

    blurTriggerElement()

    if (props.maskOnOpen) setBodyOverflowHidden(false)

    ignoreFilteredListQuery.value = false

    // When list is closed and we've edited the query, we should reset it to it's selected option's text value
    if (props.filterable) {
        const inputInitialValue = getOptionText(modelValue.value)

        if (query.value !== inputInitialValue) {
            query.value = getOptionText(internalValue.value || modelValue.value)
        }
    }

    nextTick(() => {
        emit('list-closed')

        isInputDirty.value = false
    })
}

function onOptionSelect(option: T) {
    internalValue.value = option
    isOpened.value = false
    aiAutofilled.value = false

    if (props.clearOnSelect) {
        nextTick(() => {
            query.value = ''
            internalValue.value = undefined
        })
    } else query.value = getOptionText(internalValue.value)

    nextTick(() => {
        emit('list-closed')

        isInputDirty.value = false
    })

    focusTriggerElement()
}

async function onInputChange(value: string) {
    isInputDirty.value = true
    ignoreFilteredListQuery.value = false

    if (value !== query.value) {
        emit('input-change', value)

        query.value = value

        onOpenList()
    }
}

function onClear() {
    internalValue.value = undefined

    focusTriggerElement()
    onOpenList()
}

function toLowerTrimString(value?: string | number) {
    return String(value || '')
        .toLowerCase()
        .trim()
}

function getTriggerElementNode(): HTMLInputElement | HTMLButtonElement {
    const selector = props.filterable
        ? `#${triggerRef.value?.triggerId} input`
        : `#${triggerRef.value?.triggerId}`

    return document.querySelector(selector) as
        | HTMLInputElement
        | HTMLButtonElement
}

function focusTriggerElement() {
    getTriggerElementNode()?.focus()
}

function blurTriggerElement() {
    triggerRef.value?.$el.blur()
}

function setBodyOverflowHidden(value: boolean) {
    document.body.style.overflow = value ? 'hidden' : ''
}
</script>
